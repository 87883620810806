import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import SuccessBox from 'components/Web_User_Interface/1080p_Series/System/Backup/SuccessBox';
import BackupTable from 'components/Web_User_Interface/1080p_Series/System/Backup/backupTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Software Backup",
  "path": "/Web_User_Interface/1080p_Series/System/Backup/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Backup your system settings.",
  "image": "./WebUI_1080p_SearchThumb_System_Update.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_System_Update.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Software Backup' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Backup your system settings.' image='/images/Search/WebUI_1080p_SearchThumb_System_Update.png' twitter='/images/Search/WebUI_1080p_SearchThumb_System_Update.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/System/Backup/' locationFR='/fr/Web_User_Interface/1080p_Series/System/Backup/' crumbLabel="Backup" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fa9723ee7deb5131f5aaa4b938523943/8dd93/1080p_Settings_System_Backup.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0UlEQVQoz32SW0gUURjHTxco8ZKyXmr1oVVcXa94R4syK0IU13RJ0CyVVCQCm905c2Z3Z+ZMI4QJSSFdNLCVHqSC8CFRvISXhxK74IMpPfliWWje0r3NnJhdkZ768efwnY/vz/cdzgcghIwKsiLafOdh44OJG51jDffHatpH91XdPlLbMdzUNdkqPRVsqK6+3pCckpGZBWjIQAaxrJW2UJ2Pe6e/bU8urE8tbrybXx+f/+0/p75uf1yQF3+Q3p7+m8by+lJTaU5+gSEZmCFjoRmr1UpR1Is+ByGyoriJKhchikIUQsgvz/LblZfTzvHZoSGu6pZw+Sq8dKYkPRtABtEQsT6z47mDbG551ta8LrfX7VG8sizLRCE/d1cGvw/OuD6N9w9IVabOFrq48FpaSjYw04wFIn9nh8NB3B7lzw5xuojTuSfXrpqUZULI6vs3H6jUua7G13aq+VwGQAhBhrHZbBRF9fX1EXVWFyFe4sOjEKdMPF7ZvblBCHF0dRTHHe+oiJ1ojn5VawAMgyxQfbPZbO7u7t6aG9icfrS59GV1Y4cQ18zCypPRpcnRz8s9z5zDI5hri9BooyJjUrRhrXkRamd1cpoRRWw0lodrNNHR2ojIEzXnE1urcu9dP33bZCzOSb2L+Yba6lRDUqwuLjQ0JCwo4IIuUDXTENE0lCSpqKgIAHDo4MFMDUiMAACASv2x2bKQOn1wgbE8IT9Hp9dpddEBgUeDjhwo0B5WzRbI0JDBGJtMlaGaqLLck3UXdcHhMZkJ8TgpCerjC9NS8krOVrRUVTZdySs+lZaVnphq0On1gPZ9FUIsy7Icx2GMJRELAsYiljCWBEwL2MrzvJ3DgtDWJmFRxGqBIGIMEGL9ToRYnudFUVR9e6h1krgHxqrHl8X+K7BaWb/2Owv4fwg+/DGg1CVhKFrdcLvdblPFcRzH+9iv+9fM8zzHcTab7S8U67SSu1vj9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa9723ee7deb5131f5aaa4b938523943/e4706/1080p_Settings_System_Backup.avif 230w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/d1af7/1080p_Settings_System_Backup.avif 460w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/7f308/1080p_Settings_System_Backup.avif 920w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/19805/1080p_Settings_System_Backup.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fa9723ee7deb5131f5aaa4b938523943/a0b58/1080p_Settings_System_Backup.webp 230w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/bc10c/1080p_Settings_System_Backup.webp 460w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/966d8/1080p_Settings_System_Backup.webp 920w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/61eb3/1080p_Settings_System_Backup.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fa9723ee7deb5131f5aaa4b938523943/81c8e/1080p_Settings_System_Backup.png 230w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/08a84/1080p_Settings_System_Backup.png 460w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/c0255/1080p_Settings_System_Backup.png 920w", "/en/static/fa9723ee7deb5131f5aaa4b938523943/8dd93/1080p_Settings_System_Backup.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fa9723ee7deb5131f5aaa4b938523943/c0255/1080p_Settings_System_Backup.png",
              "alt": "Web User Interface - 1080p Series - System Backup",
              "title": "Web User Interface - 1080p Series - System Backup",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <BackupTable mdxType="BackupTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      